
html,body {
    height: 100%;
    font-family: 'Poppins', sans-serif !important;
}
*{
    font-family: 'Poppins', sans-serif !important;

}
body{
    overflow-x: hidden;
}
p,h1,h2,h3,h4,h5,h6,button{
margin:0 !important;
font-family: 'Poppins', sans-serif !important;
}
:root {
    --blue: #3CB0CC;
    --black:#000;
    --white: #ffffff; 
    --grey:#BCC4DF;
    --drakgrey:#282F42;
    --lightgrey:rgb(189, 196, 224);
    --darkblue:#25384A;
    --medblue:#3D4665;
    --lightblue:rgb(40, 47, 66);
    --bodycolor:rgb(28, 30, 43);
  }
.App{
    height: 100%;
}
.MainWhole-wrapper-container{
    width:560px;
    background-color: rgb(242,242,242);
    margin:auto;
    /* height: 100vh !important; */
    border: 2px solid rgb(196 193 193);
    border-top: 0px
}
.DesktopMain-wrapper{
    /* background-color: rgb(242,242,242); */
    /* display: flex;
    align-items: center; */
}
.Login_page-wholeMain-wrapper{
        display: flex;
    align-items: center;
}
.Login_page_left_side_wrapper{
    background : linear-gradient(to right,#40B4D0,#2396B2);
    width:50%;
    height: 100vh;
}
.Login_page_left_side_wrapper_img_div{
    text-align: start;
    margin-top:44px;
}
.Login_page_left_side_wrapper_img_div img{
    width:200px;
    height:200px;
}
.Login_page_left_side_wrapper_Content_div{
    text-align: start;
    position: absolute;
    bottom: 154px;
    left: 129px;
    width: 30%;
    /* background-image: url('/public/assets/images/'); */
}
.Login_page_left_side_wrapper_Content_div h2 {
    font-size:35px;
    color: white;
    font-weight: 400;
}
.DesktopMain-wrapper .Header-login-logout-btn {
    cursor: pointer;
    color : black;
    text-decoration: none;
    font-weight: bold;
    font-size:16px;
}
.admin-header-acitve{
    color:rgb(47, 94, 164) !important;
}
.MainHeader-wrapper {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* padding: 0px 60px; */
    background: #F0F0F0;
}
.MainHeader-wrapper .col-md-3.logo {
    text-align: left;
}
.MainHeader-wrapper .col-md-3.logo a {
    display: block;
}
.MainHeader-wrapper .col-md-3.logo img {
    margin: 8px 0;
    height: 30px;
}
.MainHeader-wrapper .nav-menu ul {
    float: right;
}
.MainHeader-wrapper .nav-menu ul, .MainHeader-wrapper .nav-menu ul li {
    /* display: block; */
    /* width: 100%; */
    list-style: none !important;
    padding:0;
    margin: 0;
}
.MainHeader-wrapper .nav-menu ul li {
    display: inline-block;
    margin-left: 20px;
}
.MainHeader-wrapper .nav-menu ul li img {
    height: 25px;
    width: auto;
    margin-right: 10px;
}
.MainHeader-wrapper .nav-menu ul li a {
    display: block;
    color: #000000;
    border-bottom: 2px solid transparent;
    padding:10px 20px;
    font-size: 14px;
    font-weight: bold;
}
.MainHeader-wrapper .nav-menu ul li.list_item_active a {
    background-color: white;
    border-bottom: 2px solid #3CB0CC;
}
.logout-buton_text{
    font-weight: bold;
    font-size:15px;
    margin: 10px !important;
}
.MainHeader-wrapper .nav-menu ul li.logout-buton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.MainHeader-wrapper .nav-menu ul li.logout-buton img {
    margin-right: 0;
}
.AdminHeader-right-side-wrapper{
display: flex;
align-items: center;
justify-content: space-between;
}
.Admin_Header_list_item {
    padding: 15px 26px;
}
.Admin_Header_list_item img{
    margin-right:13px;
}
.Admin_header_Menu_icon{
    display: none;
}
/* .list_item_active{
    background-color: white;
    color:#000000;
    border-bottom: 5px solid #3CB0CC;
} */
.Header_select{
    width:100%;
    height: 39px;
    border-radius: 10px; 
    border:1px solid rgb(242,242,242);
}
.Header_menuitem{
    width:20px;
    height:20px;
    margin-right:10px;
}
.AdminHeader-right-side-wrapper div{
    /* display: flex;
    margin-top:0px;
    margin-bottom:0px;
    align-items: center; */
}
.MainHeader-logo-div{
    width:100%;
}
.MainHeader-logo-div img{
    width:100%;
}
.Main-Wrapper-Container, .ItemListMain-Wrapper, .CartPage-Main-wrapper, .QR-Code-wrapper-whole {
padding:30px;
position: relative;
/* padding-bottom:10px; */
}
.ItemListMain-Wrapper, .CartPage-Main-wrapper {
    padding-bottom: 100px;
}
.QR-Code-wrapper-whole {
    padding-bottom: 30px;
}
.HomePage-Shop-To-Gate-Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    /* z-index: 999999 !important; */
    position: relative;
}
.Homepage-content-shoptogate-image {
    width: 74%;
}
.Homepage-ShoptoGate-content-btn {
    background-color: rgb(50 89 149) !important;
    border: none !important;
    color: white !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
    font-size: 12px !important;
}
.HomePage-Shop-To-Gate-Wrapper-div-one{
    text-align: left !important;
    width: 100%;
}
.HomePage-Shop-To-Gate-Wrapper-div-two-image{
      width:65%;
      margin-bottom:5px;
} 
.HomePage-Shop-To-Gate-p{
    font-weight:500;
    font-family: 'Poppins',sans-serif;
    line-height:18px;
}
.HomePage-Shop-To-Gate-span{
    color:rgb(50 89 149);
    font-weight:bold !important;
    font-family: 'Poppins',sans-serif !important;
}
.Free-delivery-wrapper {
    text-align: left;
    background: linear-gradient(to right, rgb(244,123,38), rgb(238,48,108));
    width: 50%;
    border-radius: 0px 0px 10px 10px;
    padding: 17px;
    margin-left: 0px;
    margin-top: -10px;
    padding-top: 28px;
}
.Homepage-select-the-store-div{

}
.Homepage-select-the-store-div-h3{
    text-align: left;
    font-size: 16px;
    font-family: 'Poppins',sans-serif !important;
    letter-spacing: 0px;
    margin-bottom:10px;
    margin: 14px !important;
    margin-left: 0px !important;
}
.Homepage-select-the-store-div-inner{
display:flex;
align-items: center;
justify-content: space-between;
column-gap: 3%;
}
.Homepage-select-the-store-div-main-img {
    width: 100%;
    height: auto;
}
.Homepage-select-the-store-div-inner-div-one,.Homepage-select-the-store-div-inner-div-two{
    width: 45%;
    background: white;
    border-radius: 10px;
    padding: 20px;
}
.product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}
.Homepage-select-the-store-trending-div {
    background: #fff !important;
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
}
.product-content-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 14px;
    width:100%;
}
.Homepage-trending-items-p-tag {
    background: rgb(242,242,242);
    border-radius: 30px;
    width: 60%;
    margin: auto;
    font-size: 12px;
    padding: 3px;
    margin-top: 15px;
}
.product-content-wrapper-inner-div-one {
    width: 37%;
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 5px;
    background: #ffffff;
}
.product-content-wrapper-inner-div-two {
    width: 63%;
    text-align: left;
    margin-left: 14px;
}
.product-content-wrapper-inner-div-two h4{
    font-size:12px;
    font-weight: 700;
    font-family: 'Poppins',sans-serif;
    margin-bottom: 6px;
    margin-top:0px;

}
.product-content-wrapper-inner-div-two p{
margin:6px;
font-size:11px;
}
.ViewAll-Items-wrapper button{
    background-color: rgb(50 89 149) !important;
    border: none !important;
    color: white !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    width: 95%;
    padding: 6px 9px;
    border-radius: 6px;
}
.ViewAll-Items-wrapper {
    margin-top: 15px;
}

/* ==============================ItemListPage====================================================================================================================================================================================================================================== */
.ItemListMain-Wrapper .HomePage-Shop-To-Gate-Wrapper{
    padding: 20px;
    /* margin: 19px; */
    /* margin-bottom:10px; */

}
.ItemList-Shop-To-Gate-Wrapper-div-two{
    text-align:left;
    padding-left: 30px;
}
.ItemList-Shop-To-Gate-Wrapper-div-two p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin:0;
 }
.ItemList-Shop-To-Gate-Wrapper-div-one{
   /* padding-left: 20px; */
}
.ItemsListPage-ItemsList-containers{
    display: flex;
    justify-content: space-between;
    align-items: center;
  /*  padding: 9px 19px; */
    align-items: center;
}
.ItemsListPage-ItemsList-containers .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
} 
.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two h4 {
    font-size: 13px;
    margin-bottom:10px;
    line-height:16px;
}
.ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p {
     margin: 6px; 
    font-size: 12px;
}
.ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: inherit;
    align-items: center;
    width: 53%;
    align-self: flex-end;
}
.ItemsListPage-ItemsList-Counter-input {
    background: transparent;
    border: none;
    width: 20%;
    color: rgb(17, 43, 82);
    text-align: end;
    font-weight: 600;
    font-size: 19px;
    padding-left: 14px;
}
.ItemsListPage-ItemsList-Counter-input:focus{
    outline: none;
}
.ItemsListPage-ItemsList-Counter-btn {
    background: #ffffff;
    border: 1px solid rgb(221 219 219);
    padding: 6px 14px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(17, 43, 82);
}
.ItemListpage-ViewCart-wrapper{
    background-color: rgb(21, 64, 129);
    color:white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding:0px 34px;*/
     bottom:0;
     position:fixed;
    left:50%;
    transform:translate(-50%,0);
    width:560px;
}
.ItemListpage-ViewCart-wrapper p{
    font-family: 'Poppins', sans-serif !important;
    font-weight:600;
    cursor: pointer;
    margin:15px 25px;
}
.ItemListpage-ViewCart-wrapper-p-two{
display: flex;
align-items: center;
font-family: 'Poppins', sans-serif !important;
font-weight:400 !important;
Color:white !important;
text-decoration: none !important;
}
.ItemListpage-ViewCart-wrapper-p-two a{
    Color:white !important;
text-decoration: none !important;
}
.ItemListpage-ViewCart-wrapper-p-two span{
    margin-left:10px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================DetailsPage===================================================================================================================================================================================================================================== */
.DetailsPage-Main-wrapper {
    margin-top: 44px;
}
.DetailsPage-content-wrapper {
    background: #fff;
    padding: 20px;
    margin: 32px;
    border-radius: 14px;
    margin-top: 17px;
}
.DetailsPage-content-wrapper .Mui-selected::after{
content:"";
border:none !important;
}
.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
    border: 2px solid rgb(221 219 219) !important;
    border-radius: 30px !important;
    padding: 22px 9px !important;
    margin: 8px !important;
}
.DetailsPage-content-wrapper .css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    background-color: rgb(21, 64, 129);
    color: white !important;
    border-radius: 30px;
    padding: 0px 10px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif !important;
}
.DetailsPage-content-wrapper-inner {
    /* margin-top: 27px; */
    margin-bottom: 10px;
}
.DetailsPage-content-wrapper .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
    text-align: left !important;
    font-weight: bold;
    font-size: 17px;
    color:rgb(21, 64, 129);
    /* padding-left:20px; */
}
.DetailsPage-Main-wrapper h2{
    text-align: left;
     padding-left:33px;
     margin-bottom:0px;
     font-size: 21px;
}
.Details-page-Nextbutton-wrapper button {
    background-color: rgb(21, 64, 129);
    color: white;
    padding: 22px 34px;
    border: none;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
}
.Details-page-Nextbutton-wrapper {
   /* margin-top: 12rem; */
     bottom: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 560px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================CartPage===================================================================================================================================================================================================================================== */

.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner:nth-child(1){
display: flex;
align-items: center;
justify-content: space-between;
}
.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner div{
    text-align: left;
      width: 100%;
    padding: 0;
    margin-top: 3px;
}
.CartPage-Main-wrapper .detailspage-content-label {
    
    font-size: 13px;
}
.CartPage-Main-wrapper .DetailsPage-content-wrapper-inner p{
margin:0px;
margin-top:6px;
/* padding-left:20px; */
}
.CartPage-Main-wrapper h2{
    text-align: left;
    /* padding-left:33px; */
    margin-bottom:0px;
    font-size: 21px;  
}
.CartPage-Main-wrapper {
   /* margin-top: 36px; */
}
.CartPage-Main-wrapper .ItemsListPage-ItemsList-Counter-wrapper {
    display: flex;
    justify-content: inherit;
    align-items: center;
    width: 100%;
    margin-top: 66px;
    margin-left: 5rem;

}
.CartPage-Main-wrapper .product-content-wrapper-inner-div-one{
width:130px;
}

.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-images-class {
    width: 100%;
    /* border: 3px solid rgb(242,242,242); */
    height: auto;
}
.CartPage-Main-wrapper .product-content-wrapper {
    width: 50%;
}
.CartPage-Main-wrapper .CartPage-Product-wrapper-h2 {
    padding-left: 11px;
    font-size: 18px;
}
.CartPage-amount-padi-wrapper {
    background: rgb(21, 64, 129);
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px;
 /*   margin: 32px; */
    margin-top: 0px;
    border-radius: 0px 0px 14px 13px;
}
.CartPage-Main-wrapper .DetailsPage-content-wrapper {
    background: #fff;
    padding: 20px;
    margin:0;
    border-radius: 10px 10px 0 0;
    margin-top: 17px;
    margin-bottom:0px;
}

.CartPage-amount-padi-wrapper p {
    margin: 6px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
}
.CartPage-amount-padi-wrapper h4{
    margin:0px
}
.CartPage-Main-wrapper .ItemsListPage-ItemsList-containers .product-content-wrapper-inner-div-two p{
font-size:16px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Congratulation page===================================================================================================================================================================================================================================== */

.Congratulation-content-div-one-wrapper {
   /* background-color: rgb(119,162,47); */
    background-color: #77b43f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 20px;
}
.Congratulation_page_otp_input{
    justify-content: center;
    margin-top: 20px;
}
.Congratulation_page_otp_input input{
    width:80px !important;
    height: 80px !important;
    border-radius: 7px;
    font-weight:800;
    border: 1px solid grey;
    font-size:35px;
    background-color: transparent;
}
.Congratulation-content-div-one-wrapper-inner-two{
text-align: left;
padding-left:20px;
}
.Congratulation-content-div-one-wrapper-inner-two h1 {
    font-size: 34px;
    font-family: 'Poppins', sans-serif !important;
    margin:0;
}

.Congratulation-content-div-one-wrapper-inner-two p {
    font-size: 23px;
    margin: 0px;
    font-weight: 600;
}
.Congratulation-content-div-one-wrapper-inner-one {
    border-radius: 100%;
    width: 107px;
    height: 100px;
    overflow: hidden;
}
.Congratulation-content-div-one-wrapper-inner-one img {
    /* width: 135px;
    height: 130px; */
    width: 129%;
    height: auto;
    border-radius: 100%;
    object-fit: fill;
    padding: 0px;
    margin-top: -15px;
    margin-left: -15px;
}
.Congratulation-page-order-wrapper-below-QR{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding:10px 30px;
    margin:20px;
    justify-content: space-between;
}
.Congratulation-page-order-wrapper-below-QR h5{
    margin:0px;
    color:rgb(21, 64, 129);
    font-family: 'Poppins', sans-serif !important;
    font-weight: 800;
}
.Congratulation-page-order-wrapper-below-QR p{
    margin:0px;
    font-weight: 500;
    font-size:13px;
}
.QR-Code-wrapper-whole-inner-one h4 {
    font-size: 23px;
    margin: 0px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
}
.QR-Code-wrapper-whole-inner-one p {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}
.QR-Code-wrapper-whole-inner-one {
   /* padding: 26px; */
}
.Congratulation-page-invoice-wrapper-table-whole{
    width:92%;
    margin:20px;
    border-collapse: collapse;
}
.Congratulation-page-invoice-wrapper-table-whole thead{
border-bottom: 2px solid rgb(21, 64, 129) !important;
color: rgb(21, 64, 129) !important;
padding-bottom:20px !important;
}
.QR-Code-main-inner img {
    width: 200px !important;
    height: 200px !important;
    background: #FFF;
    padding: 10px;
}
.Congratulation-page-invoice-wrapper-table-whole tbody tr td {
    padding: 9px;
    padding-left: 0px;
    font-size: 13px;
}
.Congratulation-page-subtotal-wrapper , .Congratulation-page-total-wrapper{
    text-align: right;

}
.Congratulation-page-subtotal-wrapper {
    margin: 30px;
}
.Congratulation-page-subtotal-wrapper h4{
    margin:4px;
}
.Congratulation-page-subtotal-wrapper h4 span {
    color:black;
    font-weight: 400; 
}
.Congratulation-page-total-wrapper{
    margin: 30px;  
    background: #DCE1E7;
    font-weight: 700;
    color:rgb(21, 64, 129) !important;
    padding: 10px;
}
.Congratulation-page-total-wrapper p{
    margin:0px;
}
.Congratulation-page-total-wrapper p span{
    font-weight: 400; 
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Login===================================================================================================================================================================================================================================== */
.Login-page-form {
    width: 67%;
    padding: 25px;
    padding-top: 64px;
    border-radius: 8px;
    margin-left: 45px;
    position: relative;
    z-index: 99999;
}
.Login-page-form_logo_div{
    text-align: start;
}
.LoginPage-Main-wrapper {
    height: 100vh;
    width:50%;
    position: relative;
    display: flex;
    align-items: center;
    /* background-image:url('/public/assets/images/base.png');
    background-position: bottom right -150px;
    background-size: 313px;
    background-repeat: no-repeat; */
}
.LoginPage-Main-wrapper::before{
    content: '';
    background-image:url('/public/assets/images/logo-right.svg');
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0px;
    right: -135px;
    background-size: 277px;
    width: 178px;
    height: 130px;
    display: none;
}
.Loginpage-main-form-inner-div > div>div{
    width:100%;
    margin:auto;
    background-color: #E9EFF6;
    border-radius: 10px;
}
.Login-page-form-logo-image{
    width:20%;
}
.Circular_progress{
    text-align: center;
    justify-content: center;
}
.Login-page-form_heading_h1 {
    color: #000000;
    text-align: start;
    font-size: 26px;
    font-weight: 400;
    margin-top: 30px !important;
}
.Loginpage-main-form-inner-div{
margin-top:25px !important;
}
.Loginpage-main-form-inner-div > div>div::before{
    border-bottom: none !important;
}
.Login-page-form p {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: rgb(21, 64, 129);
    font-weight: 600;
    margin-top:4px !important;
}
.Login-page-form h6 {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-size: 15px;
}
.Loginpage\=-main-form {
    margin: 24px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 13px;
    margin-top: 29px;
}
.Loginpage-main-form-inner-div > div>div>input{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: '#000000' !important;
    letter-spacing: 0px !important;
    padding: 16px;
    border-radius: 10px;
    background-color:#E9EFF6;
}
.Loginpage-main-form-inner-div > div>div>input:-webkit-autofill{
    background-color:#E9EFF6 !important;
}
.Login-page-form input:-webkit-autofill,
.Login-page-form input:-webkit-autofill:hover, 
.Login-page-form input:-webkit-autofill:focus,
.Login-page-form input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #E9EFF6 inset !important;
    -webkit-text-fill-color:black !important;
  }
.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color:black;
    font-weight: 500;
}
.Loginpage-btn-wrapper {
    background-color: #3CB0CC;
    padding: 5px;
    border-radius: 7px;
}
.Loginpage-btn-wrapper button{
    color: #ffffff !important;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif !important;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
}
.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error{
font-weight: 400;
font-size: 10px;
padding-left:39px;
}
.Login_page_forgot_password_wrapper{
    text-align: end;
    margin-top: 20px;
    margin-bottom: 34px;
}
.Login_page_forgot_password_wrapper p{
 font-weight:300;
 text-decoration: underline;
 color:#969696;
 font-size: 14px;
}
.inactive_store{
    /* opacity: 0.5; */
    filter: grayscale();
}
.Cancelled_page_information_div {
    margin: 15px;
    background-color: #FEECED;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
}
.Cancelled_page_information_div img {
    margin:10px;
}
.Cancelled_page_information_div p{
    font-weight: bold;
    margin-left:10px !important;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================Checkout===================================================================================================================================================================================================================================== */
.Checkout-StripeMain-wrapper{  
    padding: 30px;
    height:580px; 
}
.Checkout-StripeMain-wrapper h1 {
    text-align: left;
    color: rgb(98,90,254);
    font-size: 61px;
    margin: 0px;
    letter-spacing: -2px !important;
    padding-left: 11px;
    font-family: 'Poppins', sans-serif;
}
.Checkout-StripeMain-wrapper h3 {
    text-align: left;
    padding-left: 18px;
    margin-top: 4px;
}
.Checkout-StripeMain-wrapper .FormGroup {
    background: transparent;
    box-shadow: none;
    padding: 5px;
    border: 1px solid black;
}
.Checkout-StripeMain-wrapper .FormRow{
    border-top: none !important;
}
.CheckoutStripe-content-wrapper{
    background-color: #ffffff !important;
    padding:20px !important;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================AdminDashboard===================================================================================================================================================================================================================================== */
.Dashboard-page-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00B9CF;
    color: white;
    padding: 3px 19px;
    border-radius: 10px 10px 0px 0px;
}
 .Dashboard-page-tab-header-div-two{
        display: flex;
        justify-content: space-between;
        align-items: center;
 }
 .Dashboard-page-tab-header-div-two div {
    margin: 12px;
}
.Dashboard-page-tab-header-div-one h3 {
    margin: 0px;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
}
.Dashboard-page-tab-header-div-two h3 {
    font-size: 19px;
}
.Dashboard-page-tab-header-div-two p {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}
 .Dashboard-page-tab-header-div-one h3, .Dashboard-page-tab-header-div-two p, .Dashboard-page-tab-header-div-two h3 {
    margin: 0px;
}
.AdminDashboard-whole-Main-wrapper {
      padding: 50px;
 }
 .AdminDashboard-tabs-left-side {
    width:100%;
    /* background: #fff;
    margin:10px; */
}
.AdminDashboard_tabs_left_side_wrapper{
    flex-grow: 1;
    display: flex; 
    height: 100% ;
    width:100%; 
    justify-content:space-between; 
}

.AdminDashboard-tabs-left-side  .css-8je8zh-MuiTouchRipple-root {
    display: none;
}
.AdminDashboard-tabs-right-side {
    width: 100%;
    /* background: #fff;
    margin:10px; */
}
.padding-top {
    /* padding: 40px 0; */
}
.dashboard-order-list-wrapper-inner-div-one, .dashboard-order-list-wrapper-inner-div-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 16px;
    /* padding: 0px 16px; */
    position: relative;
    margin-top:5px;
}  
.Inner-tab {
    width: 100% !important;
    /* display: block !important; */
    padding: 0px !important;
    text-align: start !important;
    max-width: 100% !important;
    /* align-items: flex-start !important; */
}
/* .Dashboard-page-seperator-line {
    position: relative;
    width:70%;
} */
.dashboard-order-list-wrapper {
    padding: 14px 0px;
    width: 100%;
    border-bottom: 3px solid rgb(242,242,242);
    background-color: #F0F6FA;
}
.dashboard-order-list-wrapper-inner-div-two:before {
    content: '';
    background-color: rgb(210, 213, 205);
    color: rgb(210, 213, 205);
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0px;
}
.Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: rgb(210, 213, 205);
    position: absolute;
    right: 18px;
    top: -3px;
    display: none;
}
.dashboard-order-list-wrapper-inner-div-one p {
    text-transform: capitalize;
    text-align: center;
    background: rgb(224,232,208);
    padding: 0px 14px;
    font-size: 12px;
    padding: 5px 16px;
    border-radius: 4px;
    /* width:100px; */
}
.dashboard-order-list-wrapper-inner-div-one h5 {
    color: #000;
    font-size: 16px;
    font-weight:bold;
}

.dashboard-order-list-wrapper-inner-div-two p {
    /* width: 35%; */
    text-transform: capitalize;
    font-weight: 600;
    /* background: transparent; */
    z-index:9 !important;
}
.dashboard-order-list-wrapper-inner-div-two-p-two{
    text-align: end;
    background-color: #F0F6FA;
    padding-left: 10px;
}
.dashboard-order-list-wrapper-inner-div-two-p-one{
    text-align: start;
    background-color: #F0F6FA;
    padding-right: 10px;
}
.AdminDashboard-tabs-left-side .Mui-selected .dashboard-order-list-wrapper{
    color: black !important;
    background: #F0F0F0;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    overflow:visible !important;
}
.AdminDashboard-tabs-left-side_heading{
  text-align: start;
  margin-bottom:20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AdminDashboard-tabs-left-side_heading h1 {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.AdminDashboard-tabs-left-side_heading svg{
    margin-bottom:0px;
}
.AdminDashboard-tabs-left-side_heading h1 span{
    color:#05B6CC;
    font-size:17px;
}

.Single-tab-wrapper-main{
    border-radius: 10px;
}
.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .dashboard-order-list-wrapper-inner-div-two:before {
    content: '';
    background-color: #00B9CF;
    color: rgb(210, 213, 205);
}
.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .dashboard-order-list-wrapper-inner-div-two-p-one,.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .dashboard-order-list-wrapper-inner-div-two-p-two{
background-color:#F0F0F0;
}
.AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:after {
    content: '.';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background:#00B9CF;
    position: absolute;
    right: 18px;
    top: -3px;
}

.dashboard-tab-inprogress {
    background-color: yellow !important;
    color: black;
}
.dashboard-tab-delivered{
    background-color: #DFEDCA;
    color: #6CA300;
    padding: 3px 17px!important;
} 
.dashboard-tab-cancel{
    background-color:rgb(244,196,196) !important;
    color:#F00000;
}
.dashboard-tab-new{
    background-color: #fff;
    color:#00B9CF;
}
.dashboard-tab-new-new{
    background-color:  #00B9CF !important;
    color:#fff;
}
.AdminDashboard-tabs-right-side .css-19kzrtu {
    padding: 0px;
}
.AdminDashboard-tabs-rightside-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00B9CF;
    color: white;
    padding: 23px 21px;
    border-radius: 10px 10px 0px 0px;
}
.rightsideheader-p {
    font-size: 13px;
    padding: 3px 17px;
    border-radius: 4px;
    font-weight: 700;
    text-transform: capitalize;
}
.rightsideheader-span{
    font-size:12px;
    font-weight: bold;
    align-self: end;
    margin-left: 5px;
}
h5.rightsideheader-h5 {
    font-weight: 800;
    font-size: 17px;
}
h5.rightsideheader-h5 span{
font-weight: 600;
font-size: 13px;
}

.AdminDashboard-tabs-rightside-content {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
 }
.AdminDashboard-tabs-rightside-content-left-side{
    width:70%;
}
.AdminDashboard-tabs-rightside-content-left-side-delivered{
    width:100%;
}
.AdminDashboard-tabs-rightside-content-right-side {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-self: baseline;
}
.AdminDashboard-tabs-rightside-content-left-side-upper-div {
    border:3px solid #F0F0F0;
    border-radius: 10px;
    padding: 20px;
    margin: 15px;
    position: relative;
    /* width:100%; */
} 
.AdminDashboard-tabs-rightside-content-left-side-lower-div{

}
.AdminDashboard-tabs-rightside-content-inner-divs{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    margin-left:0px;
}
.pin_div_wrapper{
    justify-content: center !important;
    margin-bottom:25px; 
}
/* .AdminDashboard-tabs-rightside-content-inner-divs:before{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
} */
.AdminDashboard-tabs-rightside-content-inner-divs span{  
        float: left;
		background: #FFF;
		position: relative;
        z-index: 999;
        font-weight:700;
        font-size:15px;
}
.AdminDashboard-tabs-rightside-content-inner-divs strong{
        float: right;
        background: #FFF;
        position: relative;
        z-index: 999;
        font-weight:bold;
        font-size:15px;
}
.AdminDashboard-leftside-upper-div-p ,.AdminDashboard-leftside-upper-div-h3{
text-align: left;
}
p.AdminDashboard-leftside-upper-div-p {
    font-size: 13px;
    font-weight: bold;
    margin: 0px !important;
    color:#00BCD2;
}
h3.AdminDashboard-leftside-upper-div-h3 {
    color:  #000;
    font-weight: 500;
    font-size: 15px;
    font-family: 'Poppins', sans-serif !important;
    /* text-align: center !important; */
}
.pin_h3{
    font-weight: bold !important;
    text-align: center;
}
.AdminDasboard_tabs_total_wrapper{
    padding-top:20px ;
}
.AdminDasboard_tabs_wrapper{
padding-bottom: 20px;
border-bottom:1px solid lightgrey;
}
.AdminDashboard-tabs-rightside-content-dashline{
    border-bottom: 2px dashed lightgrey;
    width: 75%;   
}
.Print_receipt_div{
    text-align: end;
    cursor: pointer;
}
.Print_receipt_div img{
    margin:10px;
}
.AdminDashboard-tabs-rightside-content-inner-divs-last{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 8px;
    /* margin-top:12px; */
    margin-left:0px;
    margin-bottom:4px;
    margin-top:0px;
}
/* .AdminDashboard-tabs-rightside-content-inner-divs-last:before{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 3px;
    content: ' ';
    margin: auto;
    border-bottom: 3px dashed #e3e3e3;
    } */
    .AdminDashboard-tabs-rightside-content-inner-divs-last span{  
        float: left;
		background: #FFF;
		position: relative;
        z-index: 999;
        font-weight:700;
        color:#00BCD2;
        font-size:15px;
}
    .AdminDashboard-tabs-rightside-content-inner-divs-last strong{
    float: right;
    background: #FFF;
    position: relative;
    z-index: 999;
    font-weight:700;
    color: #00BCD2;
}   
.AdminDashboard-tabs-rightside-content-inner-divs-last h6{
    color:rgb(35,78,147);
    font-weight: 600;
    font-size: 14px;
}
.readonly-input {
    pointer-events: none; /* Disable mouse and keyboard interactions */
    background-color: #f7f7f7; /* Optional: Set a background color for visual indication */
  }
.AdminDashboard-tabs-rightside-content-left-side-lower-div .AdminDashboard-leftside-upper-div-h3 {
    font-size: 15px;
    font-weight: 700;
    color:#00BCD2;
}
.AdminDashboard-tabs-rightside-content-left-side-lower-div  .AdminDashboard-tabs-rightside-content-inner-divs h6{
width:44%;
}
.AdminDashboard-tabs-rightside-content-right-side-btn {
    background: linear-gradient(to right,rgb(150,150,150),rgb(109,109,109));
    color:white !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 10px 11px !important;
    border-radius: 10px !important;
    width: 48%;
    margin: auto !important;
    /* margin-top: 22px !important; */
    font-size:20px !important;
}
.AdminDashboard-print-receipt-btn {
    background: #6CA300 !important;
    color:white !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 90%;
    margin: auto !important;
    margin-top: 22px !important;
    font-size:20px !important;
}
.active-btndashboard{
    background: #F00000 !important; 
}
.Dashboard-accept-order-button-wrapper {
    margin: 28px;
    margin-top: 0px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.Dashboard-accept-order-button{
    background-color: #6CA300 !important;
    color:#fff !important; 
    width:48%;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    font-size: 20px !important;
    font-family: 'Poppins', sans-serif !important;
}
.DesktopMain-wrapper .MainHeader-logo-div img{
   width:100%;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.Inner-tab.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
   position: relative;
}
.Single-tab-wrapper-main .Mui-selected::after {
    content: '' !important;
    width: 0 !important;
    height: 0 !important;
    right: -19px !important;
    top: 27px !important;
    border-top: 12px solid transparent !important;
    border-left: 20px solid #F0F0F0 !important;
    border-bottom: 13px solid transparent !important;
    position: absolute !important;
}
.Single-tab-wrapper-main button{
    overflow: visible !important;
    position: relative !important;
    align-items: stretch !important;
}
/* .custom-dropdown-menu {
    background-color: #f1f1f1;
}
.custom-dropdown-menu .dropdown-item {
    color: #333;
}
.custom-dropdown-menu .dropdown-item:hover {
    background-color: #ddd;
} */
#Store_dropdown_select button{
    background-color: transparent;
    color:#00B9CF ;
    border:none;
    border-radius: 10px;
    font-weight: bold;
    font-size:22px;
}
#Store_dropdown_select button:focus{
    border:none;
    box-shadow: none;
}
.Single-tab-wrapper-main .css-1cgx7kx-MuiTabs-scroller{
    overflow-y: unset !important; 
    overflow-x: unset !important;
}
.Single-tab-wrapper-main .css-1numsu8-MuiTabs-root {
    overflow: unset !important;
}
.Dashboard-accept-order-button-wrapper-cancel{
    margin: 28px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Dashboard-accept-order-button-cancelrefund{
 background-color: #F00000 !important;
 color:#fff !important; 
 width:60% !important;
 padding: 10px 11px !important;
 font-weight: 600 !important;
 text-transform: capitalize !important;
 border-radius: 10px !important;
 margin:10px !important;
 margin-top:0px !important;
 font-size: 20px !important;

}
.Dashboard-accept-order-button-cancel{
    background-color: #F00000  !important;
    color:#fff !important; 
    width:96% !important;
    padding: 10px 11px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin:10px !important;
    margin-top:0px !important;
    font-size: 20px !important;
   }
   .OpenDoor_active,
   .printReceipt__active{
    background-color:#00BCD2 !important;
    color: white !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 90%;
    margin: auto !important;
    margin-top: 22px !important;
    font-size: 20px !important;
   }
   
.Dashboard-accept-order-button-none{
    background: linear-gradient(to right,rgb(150,150,150),rgb(109,109,109));
    color:white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 12px 21px !important;
    border-radius: 10px !important;
    width: 77%;
    margin-top: 22px !important;
}   
 .dashboard-tab-cancelled{
    background-color:rgb(244,196,196) !important; 
 }  
 .Header-login-logout-btn{
    cursor: pointer;
 }
 .right-icon-class-wrapper{
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: #77b43f;
    width:30px;
    height:30px;
    border-radius: 100%;
    border:2px solid white;
    display:none;
 }
 .right-icon-class-wrapper svg{
    color:#fff;
    padding: 3px;
 }
 .wrong-icon-class-wrapper{
    position: absolute;
    right: -12px;
    top: -13px;
    background-color: rgb(204,0,1);
    width:30px;
    height:30px;
    border-radius: 100%;
    border:2px solid white;
    display: none;
 }
 .wrong-icon-class-wrapper svg{
    color:#fff;
    padding: 3px;

 }


/* ==============================End===================================================================================================================================================================================================================================== */

/* ==============================AdminStores===================================================================================================================================================================================================================================== */
.AdminStore-page-table-wrapper table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
}
.AdminStore-page-table-wrapper table thead{
background-color:rgb(233,238,244);
color:rgb(35,78,147);
}
.Stores-page-content-whole-wrapper {
    background: White !important;
    width: 92%;
    margin: auto !important;
    margin-top:5rem !important;
    margin-bottom:5rem !important;
}


.AdminStore-page-table-wrapper table thead th {
    padding: 11px 11px;
    font-size: 15px;
    text-align: left;
  
}
.AdminStore-page-table-wrapper table tbody tr{
    border-bottom:1px solid rgb(233,238,244);
    padding: 8px 18px !important;
}
.AdminStore-page-table-wrapper table tbody td{
    text-align: left ;
}
.Dashboard-page-tab-header.Stores-page-header{
    padding: 23px;
}
.Dashboard-page-tab-header.Stores-page-header h3 {
    font-size: 25px;
}
.Store-page-table-image-class {
    width: 50%;
    padding: 10px;
    border: 2px solid rgb(233,238,244);
}
.StorePage-storeName-td{
    padding-left:0px;
    width: 40%;
}
table.Store_page_table{
    width:100%;
    border:none;
}
.Store_page_table td h3 {
    color: #3CB0CC;
    font-size: 16px;
    font-weight: bold;
}
.Store_page_table td p {
    font-size: 14px;
    font-weight: normal;
}
.Store_page_table_Owner_span{
    text-decoration: underline;
}
.StorePage-storeName-td div h4 {
    font-weight: bold;
    font-size: 15px;
}
.StorePage-storeName-td div p {
    font-size: 13px;
}
.AdminStore-page-table-wrapper .css-qgjprd-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 40px;
    margin-top: 6px;
}  
.AdminStore-page-table-wrapper span.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
    right:0px !important;
    bottom:0px !important; 
}
.AdminStore-page-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase{
 left:0px !important;
}
.AdminStore-page-table-wrapper .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    font-family: 'Poppins', sans-serif !important;
}

.Stores_Page_store_list_table table{
   /* display: block; */
   border-collapse: collapse;
   width: 100%; 
}

.Stores_Page_store_list_table table thead{ 
    background-color: #E9EFF6;
    border-spacing: 0px;
}
.Stores_Page_store_list_table table thead th{
    color:#3CB0CC;
    padding:17px;
    text-align: start;
}
.Stores_Page_store_list_table table tbody td{
    text-align: start;
    font-weight:normal;
    font-size:14px;
    padding:17px;
}
.Stores_Page_store_list_table table tbody td img {
    width:60px;
    height:auto;
}
.Stores_page_store_list_table_content_div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Stores_page_store_list_table_content_div img{
    width:76px;
    height:76px;
    margin:18px;
}
.Stores_page_store_list_table_content_div h3{
    color:#3CB0CC;
    font-size: 17px;
    text-align: start;
}
.Stores_page_store_list_table_content_div p {
    font-size: 15px;
    line-height: 17px;
    text-align: start;
}
td.Stores_page_Select_button{
    width:167px;
}
.Stores_page_Select_button>div> label{
    font-size:13px !important;
}
.Stores_page_Select_button>div> div> div {
    height: 2px !important;
    padding: 10.5px 14px;
}
.Stores_page_Select_button>div> div>fieldset{
    border:none !important;
    box-shadow: 0px 0px 10px #00000029 !important;
    margin: 2px;
}
/* ==============================End===================================================================================================================================================================================================================================== */

/* ===================HelpPage=============================================================================================================================================================================================================================================================== */
.Help-icon-wrapper{
    position:fixed;
    bottom:0rem;
    right:31rem;
    cursor: pointer;
}
.Help-icon-wrapper img{
    object-fit: cover;
    width:70px;
    height:70px;
}
.Help-page-Header-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 24px;
}
.Help-page-Header-inner-div-one {
    display: flex;
}
.Help-page-Header-div-h4 {
    font-weight: 500;
    font-size: 20px;
}
.Help-Page-Main-content-wrapper {
    height: 100vh;
    background-color: white;
}
.Help_page-content-p{
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    text-align: start;
    margin:20px !important; 
}
h3.Help_page-content-h3 {
    text-align: start;
    margin: 20px !important;
    font-weight: 600;
    font-size: 16px;
}
h6.Help_page-content-h6 {
    text-align: start;
    margin: 20px !important;
    font-size: 12px;
    font-weight: 500;
}
.Help_page_content_heading {
    font-size: 16px;
    margin-left: 20px !important;
    font-weight: 500;
}
/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================MoreDetails=============================================================================================================================================================================================================================================================== */
.MoreDetials_page_Banner_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 37px;
}
.MoreDetials_page_Banner_wrapper img {
    width: 35%;
}
.More_Details_Page_h2{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px !important;
    text-align: left;
    padding:20px;
    padding-bottom: 0px !important;
}
.More_Details_Page_h5{
    font-weight: 700;
    color: rgb(49 96 145);
    line-height: 19px;
    margin-bottom: 0px;
    font-size: 13.9px;
    text-align: start;
}
.More-details-page-content-p {
    line-height: 20px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    text-align: start;
}
/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================Zones Page=============================================================================================================================================================================================================================================================== */
.Zone_page_content_wrapper_main_div {
    /* padding: 40px 0; */
}
.DesktopMain-wrapper > div.container{
padding-top:40px;
padding-bottom:40px;
text-align: center;
}
.Zonelist_page_list_wrapper_div{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}
.Zone_page_list_individual {
    background-color: white;
    box-shadow: 3px 8px 7px 2px #0000001a;
    margin: 20px;
    padding: 35px;
    cursor: pointer;
    position: relative;
}
.Zone_page_list_individual_inner_div{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-top: 10px;
}
.Zone_page_list_individual h2 {
    color: #3CB0CC;
    font-size: 22px;
    text-align: left;
    position: relative;
    margin-bottom: 15px !important;
}
.Zone_page_list_individual h2::after{
    content: '';
    width: 17%;
    height: 3px;
    background-color: #3CB0CC;
    position: absolute;
    left: 1px;
    top: 33px;
}
.Zone_page_list_individual_p{
    text-align: left;
}
.Zone_page_list_individual_p_two{
    font-weight: bold;
    font-size: 13px;
    text-align: left;
    margin-bottom: 13px !important;
}
.Zone_page_list_individual_inner_div p{
    font-weight:500;
}
.Zone_page_filter_div_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 17px; */
    padding-right: 0px;
    margin-bottom:17px;
}
.Zone_page_filter_div_wrapper h3{
    font-size:22px;
    font-weight:bold;
}
.Zone_page_filter_div_inner_wrapper {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    border-radius: 39px;
    padding: 2px 26px;
    background-color: #F0F0F0;
}
.Zone_page_filter_div_outer_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Zone_page_filter_div_inner_wrapper input{
    border:none;
    width: 237px;
    background-color: #F0F0F0;
}
.Zone_page_Filter_wrapper_button {
    background: white;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #E6E6E6;
    border-radius: 50px;
    padding: 5px 27px;
    margin: 18px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    cursor: pointer;
}
.Zone_page_filter_div_inner_wrapper input::placeholder{
font-weight: 600;
color: #767676 !important;
}
.Zone_page_filter_div_inner_wrapper input:focus{
    border:none  !important;
    outline: none !important;
}
.Zone_page_filter_div_inner_wrapper button{
    background: transparent;
    border:none;
}
.ZonePage_list_edit_icon {
    position: absolute;
    right: -13px;
    top: 0px;
    background-color: #3CB0CC;
    border-radius: 100%;
    padding: 10px 14px;
    color: white;
}
/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================SingleZone Page=============================================================================================================================================================================================================================================================== */
.TextareaAutosize{
    width:100% !important; 
    border:none !important; 
    border-bottom:1px solid rgba(0, 0, 0, 0.42) !important;
    height:23px !important;
}
.Zone_page_filter_div_wrapper h3 span{
   color:#3CB0CC;
}
.Single_ZonePage_Information_div {
    background-color: #E9EFF6;
    padding: 17px;
}
.Single_ZonePage_Information_div_table{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Single_ZonePage_Information_div_table h3 {
    /* width: 33.33%; */
    font-weight: 400;
    font-size: 20px;
}
.Single_ZonePage_Information_div_table p{
    /* width: 33.33%; */
    font-weight: bold;
    font-size: 19px;
}
.SingleZone_page_Store_list_wrapper {
    padding: 34px;
}
.SingleZone_page_Store_list_wrapper h4{
    text-align: start;
    padding-left: 19px;
}
.SingleZone_page_Store_list_table_wrapper tbody tr td img {
    width: 76px;
    height: 78px;
}
table.SingleZone_page_Store_list_table_wrapper {
    width: 100%;
    margin-top: 25px;
    border-top: 2px solid #3CB0CC;
    padding-top: 21px;
}
.SingleZone_page_Store_list_table_Content_div{
    text-align: start;
}
.SingleZone_page_Store_list_table_Content_td {
    /* width: 545px; */
}
.SingleZone_page_Store_list_table_Content_div h3{
     color:#3CB0CC;
     font-size: 17px;
}
.SingleZone_page_Store_list_table_Content_div p {
     font-size: 15px;
     line-height: 17px;
}
.Individual_Stores_page_left_side_content {
    background-color: #E6F0F6;
    border-radius: 15px;
}
.Individual_Stores_page_left_side_content_top_image img{
      height: 136px;
      width:100%;
      object-fit: cover;
      border-radius: 17px 17px 0px 0px
}
.Individual_Stores_page_left_side_content_small_image img {
    width: 68px;
    background-color: white;
    padding: 7px;
}s
/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================EditZone Page=============================================================================================================================================================================================================================================================== */
.Edit_ZonePage_addFleet_addRobot_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 17px;
}
.Edit_ZonePage_addFleet_addRobot_innner_div {
    background-color: #E9EFF6;
    width: 50%;
    margin: 17px;
    padding: 24px;
    text-align: start;
}
.Edit_ZonePage_addFleet_addRobot_innner_div h5{
color:#3CB0CC;
font-size: 16px;
}
.Edit_ZonePage_addFleet_addRobot_innner_div h3{
font-weight:bold;
font-size:20px;
}
/* ===================End=============================================================================================================================================================================================================================================================== */
/* =================Reports page=========================================================================================================================================================================================================================================================================== */
.Reports_page_graph_div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001F;
    height: 300px;
    border-radius: 7px;
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reports_page_orderlist_table {
    margin-top: 42px !important;
}
.reports_page_content_div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000001F;
  
}
.reports_page_content_div p {
    font-weight: bold;
    font-size: 16px;   
}
.reports_page_content_div h5 {
    color: var(--blue);
    font-size: 14px;
    font-weight: bold;
}
.reports_page_content_div h5 span{
    color: var(--black);
    font-size:12px;
}
.OrderData_div{
    margin-top:40px !important;
}

.reports_page_content_div{
    text-align: left;
    align-self: center;
    padding: 5px  27px;
    border-radius: 7px;
    margin:10px;
}
.reports_page_content_div_first{
    padding:15px 30px !important;
}
.reports_page_content_div_first img{
    height:50px;
    width:auto;
}
.reports_page_content_canceldiv h5{
    color: #E20F0F;
}
.PaginationWrapper_two{
    margin-top:20px;
  }
.PaginationWrapper_two ul li button {
    color: var(--blue);
    border:1px solid var(--blue) !important;
    border-radius: 7px;
    font-weight: bold;
  }
  .PaginationWrapper_two ul li{
    margin:5px;
  }
  .PaginationWrapper_two ul {
    justify-content: end;
  }
  .Chart_wrapper {
    height: 250px;
    padding: 8px;
    text-align: left;
    font-size: 16px;
    width:100%;
    font-weight: bold;
    padding-top: 0px;
  }
  .Chart_wrapper p{
  margin-bottom:10px !important;
  }
  .Chart_wrapper span{
    color: var(--blue);
  }
  input.Date_input {
    width: 250px;
    padding: 6px;
    border-radius: 7px;
    font-size: 13px;
    position: relative;
    border: 1px solid #dddddd;
    padding-left: 10px;
}
.calendar_icon{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    fill: #999; /* Set the icon color */
    width: 20px;
    height: 20px;
    pointer-events: none;
}
.OrderInfo_p{
    color: var(--black);
}
.Export_excel_image{
    margin-right:8px;
    margin-top: -4px;
}
/* ===================End=============================================================================================================================================================================================================================================================== */
/* ===================IndividualStore Page=============================================================================================================================================================================================================================================================== */
.Individual_Stores_Page_Content_wrapper_div {
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    padding:5rem;
}
.Individual_Stores_Page_Content_left_side_div {
    width: 40%;
    margin:15px;
}
.Individual_Stores_Page_Content_left_side_div h3{
    font-size: 27px;
    text-align: left;
    margin-bottom: 15px !important;
}
.Individual_Stores_Page_Content_left_side_div h3 span{
    color:#05B6CC;
}
.Individual_Stores_Page_Content_right_side_div {
    width: 60%;
    margin: 15px;
    background-color: #F0F0F0;
    padding: 27px;
}
.Individual_Stores_Page_Content_right_side_box{
    border-bottom: 1px !important;
    border-color: "divider" !important;
    background-color:white !important;
    width:100% !important;
    justify-content:space-between !important;
}
.Individual_Stores_page_left_side_content_inner_wrapper{
margin-top:-50px;
}
.Individual_Stores_page_left_side_content_inner_wrapper p {
    width: 80%;
    margin: auto !important;
    font-size: 14px;
}
.Individual_Stores_page_left_side_content_inner_wrapper h4{
 font-size:24px;
}
.Individual_Stores_page_left_side_icon_list {
    display: flex;
    justify-content: space-around;
    margin-top: 27px;
    padding-left:35px;
    padding-right:35px;
}
.Individual_Stores_page_left_side_single_icon{
    font-weight:600;
    font-size:2.5rem;
}
.Individual_Stores_page_left_side_icon_list_p_one{
    font-weight: 800;
    margin-top:-9px !important;
}
.Individual_Stores_page_left_side_icon_list_p_two{
    font-size:15px;
    margin-top:-9px !important;
}
.Individual_stores_page_paymentGateway_button_wrapper button {
    background-color: #00BCCD;
    border: none;
    color: white;
    padding: 14px 37px;
    width: 80%;
    margin: 20px !important;
    font-size: 25px;
    border-radius: 30px;
    font-weight: 600;
}
.Individual_stores_page_rightSide_Tabs_wrapper > div >div{
 justify-content: space-between !important;
}
.Individual_stores_page_rightSide_single_tab {
    font-size: 20px !important;
    padding: 30px !important;
    padding-left: 40px !important;
    text-transform: capitalize !important;
    color: #00BCCD !important;
}
.Individual_stores_pages_small_image {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #0000001a;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    padding: 6px;
    width:68px !important;
    height:68px !important;
}
input[type='file'] {
    opacity:0    
  }
.btn-profile-image{
    cursor: pointer;
    color:#00BCCD;
    font-weight:bold;
}  
.Individual_stores_pages_checkbox span{
    font-weight:700;
}
.Individual_stores_Page_store_settings_wrapper{
    display: flex;
    justify-content: space-between;
}
.Individual_stores_Page_settings_div_left{
  width:20%;
}
.Individual_stores_Page_settings_div_right{
  width:80%;
}
.Individual_store_page_Settings_form_wrapper{
    padding: 20px;
    text-align: left;
    padding-top:0px;
}
.Individual_store_page_Settings_form_wrapper label{
    text-align: left;
    color: #00BCCD;
    font-weight: bold;
    font-size:13px;
}
.Individual_stores_input_wrapper {
    margin-bottom: 35px;
}
.Individual_store_page_Settings_form_wrapper input::placeholder{
    font-size: 15px;
}
.Individual_store_page_Settings_form_wrapper textarea::placeholder{
    font-size: 15px;
    font-family: 'Poppins', sans-serif !important;
    color: rgba(55, 55, 55, 0.42);
    margin-bottom:5px;
    font-weight:400;
}
.Individual_stores_Automatic_scheduling_div_individual{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Individual_stores_Automatic_scheduling_div_individual_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Individual_stores_automatic_scheduling_day_div {
    width: 150px;
}
.Individual_stores_automatice_scheduling_copy_div_wrapper{
    padding:20px;
}
.Individual_stores_automatic_checkbox_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* ===================EditZone Page=============================================================================================================================================================================================================================================================== */
.Items_page_tabs_wrapper{
   background-color: #E9EFF6; 
}
.Items_page_tabs_wrapper button{
    color:#3CB0CC !important;
    font-weight:bold !important;
    font-size:14px !important;
    text-transform: capitalize !important;
}
.Items_page_tabs_wrapper .Mui-selected{
    background: white !important;
    color:#3CB0CC !important;
    font-weight:bold !important;
    font-size:17px;
    border:none !important;
    text-transform: capitalize !important;
}
.Items_page_tabs_wrapper span{
    display: none !important;
}
.Items_content_wrapper{
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}
.Items_content_Individual_item{
    background-color: #fff;
    box-shadow: 0px 0px 6px #0000001A;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    display:grid;
    grid-template-columns: 30% 70%;
    /* margin:15px; */
    position: relative;
    padding:10px;
}
.Items_content_Active_toggle{
    text-align: end;
}
.Items_single> div>div{
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;

}
.Items_single_two{
    padding-bottom:23px;
}
.Items_content_Individual_item_inner_two{
    text-align: left;
    align-self: center;
    line-height: 14px;
}
.Items_content_Individual_item_inner_two p {
    font-weight: bold;
    font-size: 13px;
}
.Items_content_Individual_item_inner_two i{
    font-size:10px;
}
.Items_content_Individual_item_inner_two h3{
    color:#5DB0CC;
    font-size: 16px;
    font-weight:bold; 
}
.Items_content_Individual_item img{
    width: 100%;
    padding-right: 20px;
}
#TablpanelID > div{
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
}

/* ==============================NewOrder Modal=================================================================================================================================================================================================================================================================== */
.NewOrder_modal_wrapper_2 h1 {
    text-align: center;
    font-size: 34px;
    color:#00BDD2;
    font-weight: bold;
    font-family: 'Poppins', sans-serif !important;
}
.NewOrder_modal_wrapper_2 p{
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}
.NewOrder_modal_wrapper_2 p>span{
  font-weight:bold
}
.NewOrder_modal_wrapper_2 > div{
    margin:10px !important;
    font-family: 'Poppins', sans-serif !important;
}
.NewOrder_modal_button_div {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}
button.NewOrder_modal_accept_button {
    background-color: #6CA300;
    color: white;
    border: none;
    padding: 10px 37px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
button.NewOrder_modal_gotoOrder_button{
    background-color:transparent;
    color:black;
    border: 1px solid black;
    padding: 10px 37px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
/* =====================================End=================================================================================================================================================================================================================================================================================== */
.CancelOrder_Modal_heading{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    padding-bottom:20px;
}
.CancelOrder_Modal_heading div{
    text-align: end;
}
.CancelOrder_Modal_heading h1 {
    font-size: 30px;
    font-weight: bold;
}
.cancelOrder_Modal_radio_wrapper{
    margin-top:15px;
    margin-bottom:15px;
}
.cancelOrder_Modal_radio_wrapper p{
    padding-left:10px;
}
.Cancel_order_modal_textarea {
    width: 100%;
    height: 130px;
}
.CancelOrder_Modal_heading_submit_btn{
    text-align: center;   
}
.CancelOrder_Modal_heading_submit_btn button{
    background-color: #00BCD2 !important;
    color: white !important;
    font-weight: 600;
    text-transform: capitalize !important;
    margin: 15px !important;
    padding: 10px 55px !important;
    border-radius: 10px !important;
    margin: auto !important;
    margin-top: 22px !important;
    border:none;
}

.Modal-button-Main-btn_yes{
    background-color:#00B9CF !important;
    color:white;
    border:none;
    margin:10px !important;
    padding: 7px 45px;
    border-radius: 5px;
}
.Modal-button-Main-btn_no{
    background-color:#fff !important;
    color:#00B9CF;
    border:1px solid #00B9CF;
    margin:10px !important;
    padding: 7px 45px;
    border-radius: 5px;
}
.Cancel-order-Modal-Mainwrapper {
    text-align: center;
}
.Modal-button-Main-wrapper {
    margin-top: 10px;
}
.PaginationWrapper ul {
    justify-content: center;
    margin-top: 10px;
}
.PaginationWrapper ul li button{
    font-weight: bold;
    font-size:17px;
}
/* ==============================Responsive===================================================================================================================================================================================================================================== */

@media screen and (max-width: 1400px) {
    .AdminDashboard-tabs-rightside-content-right-side-btn {
     font-size:15px !important;
    }
    .Dashboard-accept-order-button{
        font-size:15px !important;
    }
    }
@media screen and (max-width: 1200px) {
.container, .container-md, .container-sm {
    max-width: 95%;
    }
}
@media only screen and (max-width: 1024px) {
    .Zonelist_page_list_wrapper_div {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
    }
    .Stores_Page_store_list_table{
        overflow: hidden;
        overflow-x: scroll;
    }
    .AdminDashboard-tabs-left-side .Mui-selected::after{
    top:21px;
    }
    .Dashboard-page-seperator-line:before{
        left: -3px;
    }
    .AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:before{
        left: 0px;
    }
  
    .Admin_Header_list_item{
       padding: 16px 12px;
    }
    .DesktopMain-wrapper .Header-login-logout-btn {
      font-size:14px;
    }
    .MainHeader-wrapper {
      padding:0px 25px;
    }
    .AdminDashboard-whole-Main-wrapper {
        padding: 50px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .dashboard-order-list-wrapper-inner-div-one h5 {
        color: #000;
        font-size: 12px;
        font-weight: bold;
    }
    .dashboard-order-list-wrapper-inner-div-one p {
        font-size: 8px;
        /* width: 51px; */
        padding:3px 10px;
    }
    .dashboard-order-list-wrapper-inner-div-two p {
        /* width: 44%; */
        text-transform: capitalize;
        font-weight: 600;
        font-size: 10px;
    }
    .Dashboard-page-seperator-line {
        position: relative;
        width: 60%;
    }
    .AdminDashboard-tabs-left-side .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected .Dashboard-page-seperator-line:after {
        right: 12px;
     }
     .Dashboard-page-seperator-line:after {
        right: 13px;
    }
    .AdminDashboard-tabs-rightside-content {
        padding: 0px;
    }    
    .AdminDashboard-tabs-rightside-content-right-side-btn {
        width: 48%;
        margin: auto !important;
        /* margin-top: 22px !important; */
        font-size: 11px !important;
    }
    .Dashboard-accept-order-button{
        font-size: 11px !important;
    }
    .AdminDashboard-tabs-rightside-content-left-side-upper-div{
        padding: 12px;
    }
    h3.AdminDashboard-leftside-upper-div-h3 {
        color: #000;
        font-weight: 500;
        font-size: 13px;
    }
    .AdminDashboard-tabs-rightside-content-inner-divs strong {
        font-size: 13px;
    }
    .AdminDashboard-tabs-rightside-content-inner-divs p{
        font-size: 13px !important;
    }
    .DesktopMain-wrapper .MainHeader-logo-div img {
        width: 215px;
    }
    .AdminDashboard-tabs-left-side_heading h1 {
        font-size: 20px;
    }
    .Admin_Header_list_item img {
        margin-right: 13px;
        width: 25px;
    }
    .Items_content_wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .Items_content_Individual_item {
        grid-template-columns: 35% 65%;
    }    
    .Items_content_wrapper_tabpanel>div{
       padding-right:0px;
       padding-left:0px;

    }
    .Login_page_left_side_wrapper {
        /* display: none; */
    }
    .Login-page-form_logo_div {
        text-align: center;
    }
    .Login-page-form_heading_h1 {
        text-align: center;
    }
    .Login-page-form {
        width: 100%;
        margin-left: 0px;
        padding: 50px;
    }  
    .LoginPage-Main-wrapper::before {
        content: '';
        bottom: 25px;
        right: -95px;
        background-size: 178px;
    }  
}

@media only screen and (max-width: 992px){
    .Zonelist_page_list_wrapper_div {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .MainHeader-wrapper .nav-menu ul li{
        margin-left:0px;
    }
    /* .Stores_Page_store_list_table table thead{
        display: none !important;
    }
    .Stores_Page_store_list_table table {
        display: block;
        border-collapse: collapse;
        width: 100%;
    }
    table.Store_page_table td {
        display: block;
        text-align: center !important;
        width: 100% !important;
    }   */
 
    .Login_page-wholeMain-wrapper{
        display: block;
    }
    .Login_page_left_side_wrapper{
        width:100%;
    }
    .LoginPage-Main-wrapper{
        width:100%;
    }
    .Login-page-form {
        width: 85%;
        padding: 25px;
        padding-top: 64px;
        border-radius: 8px;
        /* margin-left: 45px; */
        margin: auto;
    }  
}
@media only screen and (max-width: 767px){
    .Login_page-wholeMain-wrapper{
        display: block;
    }
    .Login_page_left_side_wrapper{
        display: none;
    }
    .LoginPage-Main-wrapper{
        width:100%;
    }
    .Login-page-form {
        width: 100%;
        padding: 25px;
        padding-top: 64px;
        border-radius: 8px;
        /* margin-left: 45px; */
        margin: auto;
    }
    .LoginPage-Main-wrapper::before{
        display: none;
    }
    .MainHeader-wrapper{
        padding: 17px 30px;
    }
    .Zone_page_filter_div_wrapper{
        display: block;
    }
    .Zonelist_page_list_wrapper_div {
        display: block;
    }
    .Zone_page_filter_div_wrapper h3 {
        font-size: 21px;
        text-align: center;
    }
    .Zone_page_filter_div_outer_wrapper {
        display: block;
    }
    .Zone_page_Filter_wrapper_button{
    margin:auto !important;
    margin-top:20px !important;
    }
    .Single_ZonePage_Information_div_table{
        flex-direction: column;
    }
    .Single_ZonePage_Information_div_table div{
        margin:10px;
    }
    .Stores_page_store_list_table_content_div{
        width: 428px;
    }
 
    .Stores_Page_store_list_table table thead{
        display: none;
    }
  
    table.Store_page_table td {
        display: block;
        text-align: center !important;
        width: 100% !important;
    }
    table.SingleZone_page_Store_list_table_wrapper{
        overflow-x: scroll !important;
        display: block !important;
    }
   .SingleZone_page_Store_list_table_Content_div{
        width:339px !important;
   }
   .Header-login-logout-btn {
        cursor: pointer;
        text-decoration: none !important;
        color: black;
        font-size: 21px;
   }
    .AdminHeader-right-side-wrapper{
        display: none;
    }
    .Admin_header_Menu_icon{
        display: block;
    }
    .AdminDashboard-whole-Main-wrapper-div-one .MuiBox-root.css-orkwbp{
      display: block;
    }
        .AdminDashboard-tabs-left-side{
            width:100%;
            margin:-6px;
        }
        .AdminDashboard-tabs-right-side{
            width:100%;
            margin:-6px;
        }      
}

@media only screen and (min-device-width: 1920px) {
    .dashboard-order-list-wrapper-inner-div-one p{
        font-size: 8px !important;
    }
    .dashboard-tab-delivered {
        padding: 1.5px 10px!important;
    }
}
/* ==============================End===================================================================================================================================================================================================================================== */
